.navigation-primary {
  position:fixed;
  right: 0;
  top: 63px;
  width: 100%;
  height: 0;
  z-index: 1000;
  padding:0;
  background: var(--color-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  opacity: .7;
  transition: all .5s;
  transition-timing-function: ease-in-out;
}


.navigation-primary a,
.navigation-primary a:visited,
.navigation-primary a:active {
  text-transform:  uppercase;
  color: var(--color-foregound);
  font-weight: 500;
  font-size: 1.2rem;
  margin: var(--space-l) 0;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.navigation-primary-open {
  height:100%;
  padding: var(--space-l) 0;
  opacity: 1;
}

.navigation-secondary a:first-child{ margin-left: 0; }
.navigation-secondary a:hover{ color: var(--color-gray-30); }

.navigation-secondary a,
.navigation-secondary a:visited,
.navigation-secondary a:active  {
  text-transform: uppercase;
  color: var(--color-gray-70);
  font-weight: 500;
  font-size: 1rem;
  margin: 0 var(--space-m);
}

@media (min-width:600px) {
  .navigation-primary a {
    margin: 0 var(--space-l);
  }

  .navigation-primary {
    position:static;
    width: auto;
    height: auto;
    padding: 0;
    display: block;
    opacity: 1;
  }

  .navigation-primary a:active,
  .navigation-primary a:hover {border-bottom: 2px solid black;}
  .navigation-primary a:last-child {margin-right: 0;}

  .navigation-primary-open {
    width: auto;
    display: block;
  }

}

@media (min-width:1250px) {
  .navigation-primary a {
    margin: 0 var(--space-xl);
  }
}
