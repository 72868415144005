.wrapper-login {
  display: flex;
  flex-direction: column;
  margin: var(--space-xxl) auto;
}

.wrapper-login .buttons {
  text-align: right;
}
.wrapper-login button {
  margin-left: var(--space-xl)
}

@media (min-width: 600px) {
  .wrapper-login {
    width: 485px;
    margin-top: var(--space-xxxl);
  }
}
