.hero {
  width: 100%;
  height: 50vh;
  background-size: cover;
  margin-bottom: var(--space-s);
  position:relative;
  display: inline-block;
}

.hero .wrapper {
  position: absolute;
  bottom: 0;
  left:0;
  padding: var(--space-s) var(--space-m);
  text-transform: uppercase;
  font-weight: 500;
}

.hero .wrapper > label {
  margin-bottom: var(--space-s);
  display: inline-block;
  letter-spacing: 1px;
}

@media (min-width: 500px) {
  .hero {margin-bottom: var(--space-l)}
}

@media (min-width: 800px) {
  .hero .wrapper {
    font-size: 1.6rem;
    padding: var(--space-m) var(--space-l);
  }
}

@media (min-width: 870px) {
  .hero {
    height: 80vh;
  }

  .hero .wrapper {
    padding: var(--space-l) var(--space-xl);
    font-size: 2.8rem;
  }
}



.grid-square .social-block,
.grid-square .contact-form {display:none}

@media (min-width: 1180px) {
  .grid-square .contact-block {display: none}

  .grid-square .social-block,
  .grid-square .contact-form {display:block;}
}
