.grid-square {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 23.75vw;
  grid-gap: var(--space-s);
}

@media (min-width:500px) {
  .grid-square {
    grid-gap: var(--space-l);
    grid-auto-rows: 20vw;
  }
}
