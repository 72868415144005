.badge {
  position: relative;
}

.badge__count {
  display: none;
  position: absolute;
  height: 16px;
  line-height: 1;
  justify-content: center;
  align-items: center;
  background-color: hsl(0, 80%, 50%);
  color: hsl(0, 0%, 100%);
  font-size: 12px;
  font-weight: normal;
  border-radius: 16px;
  top: -16px;
  right: -16px;
  padding: 0 4px;
}

.badge--visible .badge__count {
  display: flex;
}
