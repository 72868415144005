.wrapper-shop {
  margin: var(--space-xxl) auto;
}

.wrapper-shop header h1 {margin-bottom: var(--space-l)}
.wrapper-shop header nav {margin-bottom: var(--space-l)}
.wrapper-shop header nav a {
  margin-right: var(--space-m);
  text-transform: uppercase;
  color: var(--color-foreground);
  font-weight: 300;
}
.wrapper-shop header nav a:hover,
.wrapper-shop header nav a:active {
  text-shadow: 0 0 0.1px hsl(0, 0%, 0%);
}

.wrapper-shop header nav a:visited {
    color: var(--color-foreground);
}

.wrapper-shop .message {
  text-align: center;
  padding-top: var(--space-xxxl);
}
